import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { captureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { AuthProvider } from 'react-oidc-context'

import { GlobalStyles } from '@/ui/styles/global'
import { GlobalRess } from '@/ui/styles/ress'
import BrowserRouter from '@/router/components/BrowserRouter'
import App from './App'

import { oidcConfig } from '@/configs/oidc'

import '@/assets/fonts/fonts.scss'

if (!import.meta?.env?.VITE_APP_IS_PLAYWRIGHT_TEST) {
  Sentry.init({
    dsn: (import.meta.env?.VITE_APP_SENTRY_DSN as string) ?? '',
    integrations: [Sentry.browserTracingIntegration(), captureConsoleIntegration({ levels: ['error'] })],
    tracesSampleRate: 1.0,
    ignoreErrors: ['Non-Error promise rejection captured']
  })
}

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <>
      <GlobalRess />
      <GlobalStyles />
      <AuthProvider {...oidcConfig}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </>
  )
} else {
  throw new Error('There is no root element in html')
}
